<template>
    <div v-if="companyService" class="invoice-container">
        <div class="header">
            <div class="customer-company">
                <h3>{{ $t('service_details.customer_company') }}</h3>
                <p>
                    <strong>{{ $t('service_details.name') }}:</strong>
                    {{ companyService.customer_company.attributes.name }}
                </p>
                <p>
                    <strong>{{ $t('service_details.address') }}:</strong>
                    {{ companyService.customer_company.attributes.street_address }}
                </p>
                <p>
                    <strong>{{ $t('service_details.zip') }}:</strong>
                    {{ companyService.customer_company.attributes.zip }}
                </p>
                <p>
                    <strong>{{ $t('service_details.city') }}:</strong>
                    {{ companyService.customer_company.attributes.city }}
                </p>
                <p>
                    <strong>{{ $t('service_details.country') }}:</strong>
                    {{ companyService.customer_company.attributes.country_name }}
                </p>
                <p>
                    <strong>{{ $t('service_details.company_number') }}:</strong>
                    {{ companyService.customer_company.attributes.company_number }}
                </p>
            </div>

            <div class="contractor-company">
                <h3>{{ $t('service_details.contractor_company') }}</h3>
                <p>
                    <strong>{{ $t('service_details.name') }}:</strong>
                    {{ companyService.contractor_company.attributes.name }}
                </p>
                <p>
                    <strong>{{ $t('service_details.address') }}:</strong>
                    {{ companyService.contractor_company.attributes.street_address }}
                </p>
                <p>
                    <strong>{{ $t('service_details.zip') }}:</strong>
                    {{ companyService.contractor_company.attributes.zip }}
                </p>
                <p>
                    <strong>{{ $t('service_details.city') }}:</strong>
                    {{ companyService.contractor_company.attributes.city }}
                </p>
                <p>
                    <strong>{{ $t('service_details.country') }}:</strong>
                    {{ companyService.contractor_company.attributes.country_name }}
                </p>
                <p>
                    <strong>{{ $t('service_details.company_number') }}:</strong>
                    {{ companyService.contractor_company.attributes.company_number }}
                </p>
            </div>
            <button
                class="back-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                @click="$router.go(-1)"
            >
                {{ $t('service_details.back_to_list') }}
            </button>
        </div>

        <hr class="divider" />

        <div class="service-info">
            <h4>{{ $t('service_details.title') }}: {{ getServiceType(companyService.type) }}</h4>

            <div class="service-params">
                <template v-if="companyService.type === 'ssl'">
                    <p>
                        <strong>{{ $t('service_details.ssl_type') }}:</strong>
                        {{ parsedParams(companyService.params).ssl_type }}
                    </p>
                </template>
                <template v-else-if="companyService.type === 'dns'">
                    <p>
                        <strong>{{ $t('service_details.domain_name') }}:</strong>
                        {{ parsedParams(companyService.params).domain_name }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.renewal_date') }}:</strong>
                        {{ parsedParams(companyService.params).renewal_date }}
                    </p>
                </template>
                <template v-else-if="companyService.type === 'hosting'">
                    <p>
                        <strong>{{ $t('service_details.hosting_plan') }}:</strong>
                        {{ parsedParams(companyService.params).hosting_plan }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.appName') }}:</strong>
                        {{ parsedParams(companyService.params).app_name }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.billing_cycle') }}:</strong>
                        {{ parsedParams(companyService.params).billing_cycle }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.start_date') }}:</strong>
                        {{ parsedParams(companyService.params).start_date }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.renewal_date') }}:</strong>
                        {{ parsedParams(companyService.params).renewal_date }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.description') }}:</strong>
                        {{ parsedParams(companyService.params).description }}
                    </p>
                </template>
                <template v-else-if="companyService.type === 'support'">
                    <p>
                        <strong>{{ $t('service_details.support_type') }}:</strong>
                        {{ parsedParams(companyService.params).support_type }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.description') }}:</strong>
                        {{ parsedParams(companyService.params).description }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.prepaid_time') }}:</strong>
                        {{ parsedParams(companyService.params).prepaid_time }}
                    </p>
                    <p>
                        <strong>{{ $t('service_details.limit_time') }}:</strong>
                        {{ parsedParams(companyService.params).limit_time }}
                    </p>
                </template>
                <template v-else-if="companyService.type === 'custom'">
                    <p>
                        <strong>{{ $t('custom.name') }}:</strong>
                        {{ parsedParams(companyService.params).name }}
                    </p>
                    <p>
                        <strong>{{ $t('custom.description') }}:</strong>
                        {{ parsedParams(companyService.params).description }}
                    </p>
                </template>
            </div>

            <hr class="divider" />

            <div class="amount-section">
                <div class="amount">
                    <strong>{{ $t('service_details.price') }}:</strong>
                    <p>{{ companyService.amount_format }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CompanyServicesService from '../../services/company-services.service';

    export default {
        name: 'ServiceDetails',
        data() {
            return {
                service: new CompanyServicesService(),
                companyService: null,
                companyServiceID: this.$route.params.id,
            };
        },
        async created() {
            await this.fetchCompanyService();
        },
        methods: {
            async fetchCompanyService() {
                try {
                    const { data } = await this.service.getItem(this.companyServiceID);
                    this.companyService = data.data.attributes;
                } catch (error) {
                    console.error('Error fetching company service:', error);
                }
            },
            parsedParams(params) {
                try {
                    return JSON.parse(params);
                } catch (error) {
                    console.error('Error parsing service params:', error);
                    return {};
                }
            },
            getServiceType(type) {
                const types = {
                    ssl: this.$t('company_services.types.ssl'),
                    dns: this.$t('company_services.types.dns'),
                    hosting: this.$t('company_services.types.hosting'),
                    support: this.$t('company_services.types.support'),
                    custom: this.$t('company_services.types.custom'),
                };

                return types[type] || this.$t('company_services.types.unknown');
            },
        },
    };
</script>

<style scoped lang="scss">
    .invoice-container {
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
    }

    strong {
        color: #2b2b2b;

        .theme-dark & {
            color: #ffa500;
        }
    }

    p {
        color: #2b2b2b;

        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .customer-company,
    .contractor-company {
        width: 48%;
        text-align: left;
    }

    .customer-company h3,
    .contractor-company h3 {
        font-size: 1.2em;
        color: #333;
    }

    .divider {
        margin: 20px 0;
        border: none;
        border-top: 1px solid #ddd;
    }

    .service-info h4 {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: #333;
    }

    .service-params p {
        margin: 5px 0;
    }

    .amount-section {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    .amount {
        font-weight: bold;
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .back-button {
        max-height: 40px;
    }

    .theme-dark .invoice-container {
        background: #2b2b2b;
        color: #ccc;
        border-color: #444;
    }

    .theme-dark .divider {
        border-color: #555;
    }

    .customer-company p,
    .contractor-company p {
        margin: 5px 0;
        font-size: 0.95em;
    }
</style>
