import axios from 'axios';

class Service {
    save(data) {
        return axios.post('/api/v1/services', data);
    }

    getAll() {
        return axios.get('/api/v1/services');
    }

    update(id, data) {
        return axios.put(`/api/v1/services/${id}`, data);
    }

    getById(id) {
        return axios.get(`/api/v1/services/${id}`);
    }

    destroy(id) {
        return axios.delete(`/api/v1/services/${id}`);
    }
}

export default Service;
