<template>
    <div class="service-update">
        <ServiceForm :serviceData="service" :isUpdateMode="true" />
    </div>
</template>

<script>
    import ServiceForm from '@/modules/services/components/ServiceForm.vue';
    import Service from '../../services/services.service';
    export default {
        components: {
            ServiceForm,
        },
        data() {
            return {
                service: null,
                Service: new Service(),
            };
        },
        async created() {
            const serviceId = this.$route.params.id;
            await this.fetchService(serviceId);
        },
        methods: {
            async fetchService(id) {
                try {
                    const { data } = await this.Service.getById(id);
                    this.service = data.data.attributes;
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Get By ID Service errors', error);
                    }
                }
            },
        },
    };
</script>
