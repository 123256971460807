<template>
    <div class="service">
        <div class="service__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'service',
    };
</script>
