<template>
    <div class="create-service">
        <form @submit.prevent="submitForm">
            <button
                class="back-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                @click="goBack"
            >
                Back
            </button>
            <h1>{{ pageTitle }}</h1>
            <div class="form-group">
                <label for="name">{{ $t('services.names.name') }}</label>
                <input id="name" v-model="service.name" type="text" required />
            </div>
            <div class="form-group">
                <label for="description">{{ $t('services.names.description') }}</label>
                <textarea id="description" v-model="service.description" required></textarea>
            </div>
            <button
                type="submit"
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
            >
                {{ buttonText }}
            </button>
        </form>
    </div>
</template>

<script>
    import Service from '@/modules/services/services/services.service';
    export default {
        props: {
            serviceData: {
                type: Object,
                default: () => ({}),
            },
            isUpdateMode: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                service: {
                    name: '',
                    description: '',
                },
                Service: new Service(),
            };
        },
        watch: {
            serviceData: {
                handler(newData) {
                    this.service = { ...newData };
                },
                immediate: true,
            },
        },
        computed: {
            pageTitle() {
                return this.isUpdateMode
                    ? this.$t('services.names.updateTitle')
                    : this.$t('services.names.createTitle');
            },
            buttonText() {
                return this.isUpdateMode ? this.$t('services.buttons.update') : this.$t('services.buttons.create');
            },
        },
        methods: {
            async submitForm() {
                try {
                    let response;
                    if (this.isUpdateMode) {
                        response = await this.Service.update(this.service.id, { service: this.service });
                    } else {
                        response = await this.Service.save({ service: this.service });
                    }

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });

                    if (this.isUpdateMode) {
                        this.$router.push({ path: `/services/view/${response.data.data.id}` });
                    } else {
                        this.$router.push({ path: `view/${response.data.data.id}` });
                    }
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Form submission errors', error);
                    }
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .create-service {
        padding: 20px;
        color: #000;
        transition: background-color 0.3s, color 0.3s;

        &.theme-dark {
            background-color: #333;
            color: #ffa500;
        }

        form {
            position: relative;
        }

        .back-button {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 10px 20px;
        }

        h1 {
            margin-bottom: 20px;
            color: #333;
            text-align: center;

            .theme-dark & {
                color: #ffa500;
            }
        }

        .form-group {
            margin-bottom: 15px;

            label {
                display: block;
                margin-bottom: 5px;

                .theme-dark & {
                    color: #ffa500;
                }
            }

            input,
            textarea,
            select {
                width: 100%;
                padding: 10px;
                font-size: 16px;
                box-sizing: border-box;
                border: 1px solid #ccc;
                border-radius: 4px;
                transition: border-color 0.3s, background-color 0.3s, color 0.3s;

                .theme-dark & {
                    border-color: #333;
                    background-color: #555;
                    color: #ffa500;
                }

                &:focus {
                    border-color: #42b983;

                    .theme-dark & {
                        border-color: #ffa500;
                    }
                }
            }
        }
    }
</style>
