<template>
    <ServiceForm />
</template>

<script>
    import ServiceForm from '@/modules/services/components/ServiceForm.vue';
    export default {
        components: {
            ServiceForm,
        },
    };
</script>
