<template>
    <div>
        <component :is="formComponent" v-if="serviceData && formComponent" :object="serviceData" />
        <div v-else class="not-found">
            <p>Form not Found</p>
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                @click="$router.go(-1)"
            >
                Back
            </button>
        </div>
    </div>
</template>

<script>
    import Service from '../../services/services/services.service';
    import FormConfig from '../forms/formComponentsConfig';

    export default {
        data() {
            return {
                serviceData: null,
                formComponent: null,
                service: new Service(),
            };
        },
        computed: {
            serviceId() {
                return this.$route.params.service_id;
            },
        },
        mounted() {
            this.fetchServiceData();
        },
        methods: {
            async fetchServiceData() {
                try {
                    const { data } = await this.service.getById(this.serviceId);
                    this.serviceData = data.data.attributes;

                    this.getFormComponent(this.serviceData.service_type);
                } catch (error) {
                    console.error('Error fetching service data:', error);
                }
            },
            async getFormComponent(type) {
                try {
                    if (FormConfig[type]) {
                        const module = await FormConfig[type]();
                        this.formComponent = module.default;
                    } else {
                        console.error(`No component found for type: ${type}`);
                        this.formComponent = null;
                    }
                } catch (error) {
                    console.error('Error loading form component:', error);
                    this.formComponent = null;
                }
            },
        },
    };
</script>
